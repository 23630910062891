<template>
  <div class="home">
    <v-layout row wrap class="d-flex justify-center">
      <v-flex xs12 md12 lg12 class="mb-5">
        <h1 class="f-alfa primary--text">
          <router-link to="/">Pro Track</router-link> / Add Client
        </h1>
      </v-flex>
      <v-flex xs12>
        <v-layout row wrap>
          <v-flex xs12 md12 lg12 class="pa-3">
            <p class="mb-1 grey--text"><b>Client Name</b></p>
            <v-text-field
              label="Client Name"
              single-line
              outlined
              v-model="name"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 lg6 class="pa-3">
            <p class="mb-1 grey--text"><b>User ID</b></p>
            <v-text-field
              label="User ID"
              single-line
              outlined
              v-model="userId"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 lg6 class="pa-3">
            <p class="mb-1 grey--text"><b>Password</b></p>
            <v-text-field
              label="Password"
              single-line
              outlined
              v-model="password"
            ></v-text-field>
            <div class="d-flex justify-space-between mt-2 mb-5">
              <a @click="generateSecurePassword"><u>Auto Generate</u></a>
            </div>
          </v-flex>
          <v-flex xs12 class="pa-3">
            <v-btn x-large class="primary black--text" @click="createRole"
              >Submit</v-btn
            >
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
    
    <script>
import { BASE_URL } from "@/config";
import Axios from "axios";

export default {
  data() {
    return {
      name: "",
      userId: "",
      password: "",
    };
  },
  mounted() {},
  methods: {
    generateSecurePassword(length = 12) {
      const charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()-_=+";
      let _password = "";
      for (let i = 0; i < 12; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        _password += charset.charAt(randomIndex);
      }
      this.password = _password;
    },
    async createRole() {
      let url = BASE_URL + "/department";
      let payload = {
        department: this.department,
      };
      if (this.addParent && this.parentDepartment) {
        payload.under = this.parentDepartment;
      }
      let { data } = await Axios.post(url, payload);
      this.$router.push("/departments");
      this.$toastr.s(
        "New department has been created successfully!",
        "Department Added"
      );
    },
  },
};
</script>